<template>
  <div class="sctp-container">
    <div class="pad-tb10">
      <div class="mg-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>我的订单</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="order-detail">
        <el-row type="flex" :gutter="15">
          <el-col :span="5">
            <div class="sctp-bg-white" style="height: 100%;">
              <div class="flex flex-center" style="font-size: 48px;font-weight: 700;height: 100%;">
                广告
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="flex-item1">
              <div class="card pad15">
                <div class="sctp-card-title">订单详情</div>
              </div>
              <template v-if="orderDetailConfig.detail">
                <div class="card">
                  <el-card shadow="never">
                    <div class="flex flex-center">
                      <div class="mg-b20">
                        订单状态：<span class="fz-16 sctp-bold">{{ status }}</span>
                      </div>
                    </div>
                    <div>
                      <order-step :step-array="stepArray" :active="currentStep"></order-step>
                    </div>
                  </el-card>
                </div>

                <template v-if="orderDetailConfig.customerServiceApply">
                  <div class="card">
                    <div
                      class="sctp-flex sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                      售后申请
                    </div>
                    <div class="pad15">
                      <div>申请状态：{{
                          {
                            0: '申请中',
                            1: '商家已同意',
                            2: '商家已拒绝',
                            3: '已撤销'
                          }[orderDetailConfig.customerServiceApply.status]
                        }}
                      </div>
                      <div>申请类型：{{
                          {
                            1: '退款', 2: '退货', 3: '换货', 4: '补发商品'
                          }[orderDetailConfig.customerServiceApply.type]
                        }}
                      </div>
                      <div>
                        申请详细说明：{{ orderDetailConfig.customerServiceApply.detail }}
                      </div>
                      <template
                        v-if="orderDetailConfig.customerServiceApply.pictures && orderDetailConfig.customerServiceApply.pictures.length > 0">
                        <div style="width: 100%;overflow:auto;">
                          <template
                            v-for="picture in orderDetailConfig.customerServiceApply.pictures">
                            <el-image
                              style="width: 60px;height: 60px;"
                              :src="picture"
                              fit="cover"
                            ></el-image>
                          </template>
                        </div>
                      </template>
                      <template
                        v-if="[1,2].includes(orderDetailConfig.customerServiceApply.type)">
                        <div>
                          用户名： {{ orderDetailConfig.customerServiceApply.username }}
                          手机号： {{ orderDetailConfig.customerServiceApply.phone }}
                        </div>
                      </template>
                      <template
                        v-if="[3,4].includes(orderDetailConfig.customerServiceApply.type)">
                        收货地址：
                        {{ orderDetailConfig.customerServiceApply.receiver }}
                        {{ orderDetailConfig.customerServiceApply.addressPhone }}
                        {{ orderDetailConfig.customerServiceApply.address }}
                        {{ orderDetailConfig.customerServiceApply.addressDetail }}
                      </template>
                    </div>
                  </div>
                </template>

                <div class="card order-info-card">
                  <el-card shadow="never">
                    <div>
                      <el-row>
                        <el-col :span="8">
                          <div class="card-wrap border-right">
                            <div class="card-wrap__title">
                              买家信息
                            </div>
                            <div class="card-wrap__content">
                              <div class="card-item-wrap">
                                <div class="card-item__label">账号：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.userAccount }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">手机号码：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.userPhone }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="card-wrap border-right">
                            <div class="card-wrap__title">
                              卖家信息
                            </div>
                            <div class="card-wrap__content">
                              <div class="card-item-wrap">
                                <div class="card-item__label">卖家姓名：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.sellerUserName }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">卖家类型：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.sellerUserTypeName }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">卖家地址：</div>
                                <div class="card-item__content">{{
                                    getProvince(orderDetailConfig.detail.sellerProvinceCode)
                                  }}-{{ getCity(orderDetailConfig.detail.sellerCityCode) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="card-wrap">
                            <div class="card-wrap__title">
                              付款信息
                            </div>
                            <div class="card-wrap__content">
                              <div class="card-item-wrap">
                                <div class="card-item__label">支付方式：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.payMethodName }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">支付时间：</div>
                                <div class="card-item__content">{{
                                    dateToString(orderDetailConfig.detail.payTime)
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row style="border-top: 1px #eee solid;">
                        <el-col :span="8">
                          <div class="card-wrap border-right">
                            <div class="card-wrap__title">
                              发货信息
                            </div>
                            <div class="card-wrap__content">
                              <div class="card-item-wrap">
                                <div class="card-item__label">发货方式：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.deliveryMethodName }}
                                </div>
                              </div>
                              <template v-if="2 === orderDetailConfig.detail.deliveryMethod">
                                <div class="card-item-wrap">
                                  <div class="card-item__label">网盘地址：</div>
                                  <div class="card-item__content">
                                    {{ orderDetailConfig.detail.deliveryNetdisc }}
                                  </div>
                                </div>
                                <div class="card-item-wrap">
                                  <div class="card-item__label">网盘密码：</div>
                                  <div class="card-item__content">
                                    {{ orderDetailConfig.detail.deliveryNetdiscPassword }}
                                  </div>
                                </div>
                                <div class="card-item-wrap">
                                  <div class="card-item__label">解密密码：</div>
                                  <div class="card-item__content">
                                    {{ orderDetailConfig.detail.deliveryNetdiscUnzipPassword }}
                                  </div>
                                </div>
                              </template>
                              <div>
                                <el-alert
                                  class="fz-12"
                                  title="用户须知：商品一旦发货，不支持退货"
                                  type="info"
                                  center
                                  :closable="false"
                                  show-icon
                                ></el-alert>
                              </div>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="card-wrap border-right">
                            <div class="card-wrap__title">
                              收货人信息
                            </div>
                            <div class="card-wrap__content">
                              <div class="card-item-wrap">
                                <div class="card-item__label">收货人：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.addressName }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">地址：</div>
                                <div class="card-item__content">{{
                                    orderDetailConfig.detail.address
                                  }}{{ orderDetailConfig.detail.addressDetail }}
                                </div>
                              </div>
                              <div class="card-item-wrap">
                                <div class="card-item__label">手机号码：</div>
                                <div class="card-item__content">
                                  {{ orderDetailConfig.detail.addressPhone }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="card-wrap">
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-card>
                </div>

                <div class="card">
                  <div
                    class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                    <div style="margin-right: 30px;">订单号：{{
                        orderDetailConfig.detail.orderNo
                      }}
                    </div>
                    <div>提交时间：{{ dateToString(orderDetailConfig.detail.submitTime) }}</div>
                  </div>
                  <template v-for="store in orderDetailConfig.detail.stores">
                    <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                      <el-image
                        class="sctp-mar-r15"
                        style="width: 20px; height: 20px"
                        :src="store.shopLogo"
                        fit="cover"></el-image>
                      <div>店铺：{{ store.shopName }}</div>
                    </div>
                    <div class="goods-list">
                      <template v-for="product in store.products">
                        <el-row class="sctp-pad15 goods-item">
                          <el-col :span="10">
                            <div class="">
                              <div class="sctp-flex">
                                <el-image
                                  class="sctp-mar-r15"
                                  style="width: 80px; height: 80px"
                                  :src="product.preview"
                                  fit="cover"></el-image>
                                <div class="sctp-flex-item-1">
                                  <div>
                                    {{ product.productName }}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <template v-if="product.copyrightPrice != null">
                                  <div class="pad-tb5">版权费用：{{ product.copyrightPrice }}￥</div>
                                </template>
                                <template v-if="product.installPrice != null">
                                  <div class="pad-tb5">提供远程安装服务：{{ product.installPrice }}￥</div>
                                </template>
                              </div>
                            </div>
                          </el-col>
                          <el-col :span="2">
                            <div class="sctp-tc">¥{{ product.price.toMoney() }}</div>
                          </el-col>
                          <el-col :span="2">
                            <div class="sctp-tc">
                              x{{ product.amount }}
                            </div>
                          </el-col>
                          <el-col :span="3">
                            <div class="sctp-color-main sctp-tc">¥{{
                                product.amount.mul(product.price).toMoney()
                              }}
                            </div>
                          </el-col>
                          <el-col :span="2">
                          </el-col>
                        </el-row>
                      </template>
                    </div>
                  </template>
                  <div class="pad-tb15 pad-lr15">
                    <div style="border-top: 1px #eee dashed;">
                      <div style="text-align: right;line-height: 2;">
                        <div class="mg-t15">
                          商品总价：￥{{ productTotalPrice }}
                        </div>
                        <div class="fz-16 mg-t15">
                          <div>
                            实付款：￥{{ orderDetailConfig.detail.payAmount }}
                          </div>
                          <template v-if="orderDetailConfig.detail.originPayAmount">
                            <div class="fz-12 fc-red">(商家下单后调价)</div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {order} from '@/apis/index'
import area from "@/resources/js/area";

export default {
  name: "detail.vue",
  components: {
    OrderStep: () => import('./OrderStep'),
  },
  props: {
    id: {
      type: Number,
    }
  },
  data() {
    return {
      orderDetailConfig: {
        loading: true,
        detail: null,
        customerServiceApply: null,
        loadData: () => {
          this.orderDetailConfig.loading = true;
          return order.orderDetail({
            userId: this.user.userId,
            orderId: this.id,
          }).then(res => {
            let {data, customerServiceApply} = res;
            this.orderDetailConfig.detail = data;
            this.orderDetailConfig.customerServiceApply = customerServiceApply;
          }).finally(() => {
            this.orderDetailConfig.loading = false;
          });
        }
      },
      orderOptions: {
        options: {
          delete: ({orderId}) => {
            this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
              type: 'warning'
            }).then(() => {
              order.deleteOrder({
                orderId: orderId,
                userId: this.user.userId
              }).then(res => {
                this.$message.success('删除成功');
                this.$router.push({
                  path: '/user/order'
                })
              })
            })
          },
          cancel: ({orderId}) => {
            this.$confirm('确认取消订单?', '提示', {
              type: 'warning'
            }).then(() => {
              order.cancelOrder({
                orderId: orderId,
                userId: this.user.userId,
              }).then(res => {
                this.$message.success('取消订单成功');
                this.orderDetailConfig.loadData();
              });
            });
          },
          requestRefund: ({orderId}) => {
            this.$confirm('确认申请退款?', '提示', {
              type: 'warning'
            }).then(() => {
              this.goPage(`/order/refund/${orderId}`)
            })
          },
          confirmReceipt: ({orderId}) => {
            this.$confirm('请确认已经收到商品, 是否继续?', '提示', {
              type: 'warning'
            }).then(() => {
              order.confirmReceipt({
                orderId: orderId,
                userId: this.user.userId,
              }).then(res => {
                this.$message.success('确认收货成功');
                this.orderDetailConfig.loadData();
              })
            })
          },
          evaluate: ({orderId}) => {
            this.$router.push({
              path: `/order/evaluate/${orderId}`
            })
          },
          saleAfter: ({orderId}) => {
            this.$router.push({
              path: `/order/salesAfterRequest/${orderId}`
            })
          },
          payment: ({orderId}) => {
            this.$router.push({
              path: `/order/pay/${orderId}`
            })
          },
        },
        onOptionsClick: (option) => {
          let {orderId} = this.orderDetailConfig.detail;
          if (this.orderOptions.options[option]) {
            this.orderOptions.options[option].call(null, {orderId: orderId});
          }
        }
      }
    }
  },
  computed: {
    productTotalPrice() {
      let {stores} = this.orderDetailConfig.detail;
      let totalPrice = 0;
      if (stores) {
        stores.forEach(store => {
          if (store.products) {
            store.products.forEach(product => {
              totalPrice = totalPrice + (product.amount.mul(product.price));
            })
          }
        })
      }
      return totalPrice;
    },
    status() {
      let {orderStatus} = this.orderDetailConfig.detail;
      if (orderStatus) {
        return {
          0: '待支付',
          1: '已取消',
          2: '待发货',
          3: '待收货',
          4: '待评价',
          5: '已完成',
        }[orderStatus];
      }
      return '';
    },
    currentStep() {
      let active = 1;
      let {orderStatus} = this.orderDetailConfig.detail;
      if (orderStatus) {
        switch (orderStatus) {
          case 0:
            active = 1;
            break;
          case 1:
            active = 0;
            break;
          case 2:
            active = 2;
            break;
          case 3:
            active = 4;
            break;
          case 4:
            active = 5;
            break;
          case 5:
            active = 6;
            break;
        }
      }
      return active;
    },
    stepArray() {
      let {submitTime, payTime, deliveryTime, receiptTime, evaluateTime, finishTime} = this.orderDetailConfig.detail;
      let stepArray = [
        {
          title: '提交订单',
          description: this.dateToString(submitTime),
          date: submitTime,
        },
        {
          title: '确认支付',
          description: this.dateToString(payTime),
          date: payTime,
        },
        {
          title: '订单发货',
          description: this.dateToString(deliveryTime),
          date: deliveryTime,
        },
        {
          title: '确认收货',
          description: this.dateToString(receiptTime),
          date: receiptTime,
        },
        {
          title: '评价',
          description: this.dateToString(evaluateTime),
          date: evaluateTime,
        },
        {
          title: '已完成',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
      ];
      return stepArray;
    },
    getCity() {
      return function (cityCode) {
        return area.parseCity(cityCode).name;
      }
    },
    getProvince() {
      return function (provinceCode) {
        return area.parseProvince(provinceCode).name;
      }
    }
  },
  beforeMount() {
    this.orderDetailConfig.loadData();
  }
}
</script>

<style scoped lang="scss">
.order-detail {
  /deep/ {
    .sctp-card-title {
      border-left: 3px solid $main-color;
      color: $main-color;
      padding-left: 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .order-info-card {
      .border-right {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          border-right: 1px #eee solid;
          top: 15px;
          right: 0;
          bottom: 15px;
        }
      }

      .card-wrap {
        padding: 15px;

        .card-wrap__title {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .card-wrap__content {
          .card-item-wrap {
            display: flex;
            line-height: 2;

            .card-item__label {
              width: 80px;
            }

            .card-item__content {
              flex: 1;
              overflow: hidden;
              word-break: break-word;
            }
          }
        }
      }
    }

    .fz-12 {
      .el-alert__title {
        font-size: 12px;
      }
    }
  }
}
</style>


























